import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/ZAyu_N28OUY">
    <SEO title="Level Up - Romans: Principles" />
  </Layout>
)

export default SermonPost
